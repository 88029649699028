.b-posts {
	padding: 34px 0 40px 0;

	@include media-breakpoint-up(lg) {
		padding: 70px 0;
	}

	img {
		width: 100%;
		height: 205px;
		object-fit: cover;
	}

	&__row-heading {
		padding: 24px 0 48px 0;

		@include media-breakpoint-up(lg) {
			padding: 48px 0;
		}
	}

	&__heading {
		font-size: em(20);
		line-height: normal;
		margin: 0;
		color: $color-secondary;

		@include media-breakpoint-up(lg) {
			font-size: em(32);
		}
	}

	&__list {
		@include media-breakpoint-down(lg) {
			margin-bottom: 48px;
		}
	}

	&__content {
		padding: 24px 24px 32px 24px;
	}

	&__preview {
		border: 1px solid $color-fill-grey;
		border-radius: $global-radius-4;
		overflow: hidden;
	}

	&__author {
		font-weight: 500;
		font-size: em(12);
		line-height: normal;
		display: block;
		margin: 0 0 24px 0;
		color: $color-grey-dark;
	}

	&__title {
		font-size: em(24);
		line-height: normal;
		color: $color-secondary;
		margin: 0 0 24px 0;
	}

	&__excerpt {
		font-size: em(16);
		line-height: 1.5;
		color: $color-secondary;
	}

	&__more {
		padding-left: 0;
	}

	&__all {
		@include media-breakpoint-down(lg) {
			width: 100%;
		}
	}
}
