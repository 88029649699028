$shadow: 0 4px 4px rgba(0, 0, 0, 0.24);

.b-search {
	&__wrapper {
		background-color: $color-white;
		box-shadow: $shadow;
		border-radius: $global-radius-4 * 3;
		padding: 24px;
		position: relative;
		z-index: 1;
		margin: 24px 0 30px 0;
		border: 1px solid $color-fill-grey;

		@include media-breakpoint-up(xl) {
			margin: -50px 0 0 0;
			border: 0;
			border-radius: $global-radius-5 * 2;
		}
	}

	&__row-item {
		gap: 24px 36px;
	}

	&__item {
		&--select {
			@include media-breakpoint-up(xl) {
				width: 100%;
				max-width: 222px;
			}
		}

		&--disabled {
			* {
				color: $color-grey-dark !important;
				border-color: $color-grey-dark !important;
			}

			.c-form__selected::after {
				content: url('~assets/images/icon-select-disabled.svg');
			}
		}
	}

	&__submit {
		input {
			@include media-breakpoint-down(xl) {
				width: 100%;
				font-size: em(20);
			}
		}
	}

	&__tooltip {
		margin-left: 8px;
		cursor: pointer;
		position: relative;

		&::after {
			content: attr(data-content);
			display: none;
			position: absolute;
			bottom: calc(100% + 15px);
			min-width: 150px;
			font-size: 12px;
			line-height: normal;
			color: $color-secondary;
			padding: 12px;
			border-radius: $global-radius-5 * 2;
			left: 0;
			background-color: $color-white;
			box-shadow: $shadow;
		}

		&:hover,
		&:focus {
			&::after {
				display: block;
			}
		}
	}
}
