.c-hamburger {
	z-index: 10;
	cursor: pointer;
	// width: 48px;
	// height: 48px;
	// margin-right: 9px;

	// &--colored {
	// 	path {
	// 		stroke: $color-brand-1;
	// 	}
	// }

	&__open {
		display: block;
	}

	&__close {
		display: none;
	}

	&.active {
		.c-hamburger__open {
			display: none;
		}

		.c-hamburger__close {
			display: block;
		}
	}
}
