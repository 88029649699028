/* stylelint-disable no-descending-specificity */
.c-form {
	$form: &;

	&__select {
		position: relative;
		display: flex;
	}

	input:not([type='checkbox']),
	textarea,
	&__selected {
		&:not([type='submit']) {
			font-family: inherit;
			width: 100%;
			background-color: $color-white;
			border: 2px solid $color-secondary;
			border-radius: $global-radius-5;
			font-style: normal;
			font-weight: 500;
			font-size: em(16);
			line-height: 1.25;
			padding: 7px 13px;
			color: $color-secondary;
			transition: $global-transition;
			letter-spacing: 0.457143px;

			@include media-breakpoint-up(lg) {
				padding: 9.5px 13px;
			}

			&::placeholder {
				color: var(--text-placeholder);
			}

			&:focus {
				border-color: $color-secondary;
				background: rgba($color-secondary, 0.1);
				outline: 4px solid rgba($color-secondary, 0.3);
			}
		}
	}

	&__selected {
		cursor: pointer;
		position: relative;
		// display: flex;
		// align-items: center;
		// justify-content: space-between;
		padding-right: 50px !important;
		overflow: hidden;
		white-space: nowrap;
		text-align: left;
		text-overflow: ellipsis;
		display: inline-block;

		&::after {
			transition: $global-transition;
			content: url('~assets/images/icon-select.svg');
			height: 12px;
			width: 12px;
			margin-left: 12px;
			position: absolute;
			right: 13px;
		}

		&--arrow-active {
			border-color: $color-secondary !important;
			background: rgba($color-secondary, 0.1);
			outline: 4px solid rgba($color-secondary, 0.3);
			background-color: rgba($color-secondary, 0.1) !important;

			&::after {
				transform: rotate(180deg);
			}
		}
	}

	&__select-items {
		position: absolute;
		background-color: $color-white;
		margin-top: 10px;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 99;
		border: 2px solid $color-secondary;
		border-radius: $global-radius;
		max-height: 400px;
		overflow: auto;

		div {
			color: $color-secondary;
			padding: 12px 10px;
			font-weight: 500;
			font-size: em(16);
			cursor: pointer;

			&:hover {
				background-color: rgba($color-secondary, 0.1);
			}
		}

		.same-as-selected {
			background-color: rgba($color-secondary, 0.1);
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}

	&__label {
		font-size: em(14);
		line-height: normal;
		margin-bottom: 4px;
		color: $color-secondary;
	}

	&__checkbox {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.5;
		padding: 4px 0;
		color: $color-secondary;

		label {
			display: flex;
			align-items: center;
		}

		input {
			margin-right: 14px;
			width: 16px;
			height: 16px;
			border-radius: 2px;
			border-color: $color-grey-disable;
		}
	}

	.hidden {
		display: none;
	}
}
/* stylelint-enable no-descending-specificity */
