/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
	z-index: 2;

	@include media-breakpoint-down(lg) {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		height: 100vh;
		background-color: $color-white;
		padding-top: 80px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		gap: 24px;

		@include media-breakpoint-down(lg) {
			gap: 46px;
		}
	}

	&__link {
		font-weight: 500;
		font-size: em(16);
		line-height: 1.25;
		letter-spacing: 0.25px;
		color: $color-secondary;
		transition: $global-transition;

		&:hover {
			color: $color-fill-accent;
		}
	}
}
