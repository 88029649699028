.c-footer-nav {
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		&--empty {
			margin-bottom: 30px;
		}
	}

	&__link {
		width: 100%;
		font-size: em(14);
		line-height: 1.71;
		transition: $global-transition;

		&:not([href='#']) {
			&:hover {
				color: $color-fill-accent !important;
			}
		}

		&[href='#'] {
			cursor: unset;
		}
	}
}
