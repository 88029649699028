.b-slider {
	&__text {
		font-weight: 700;
		font-size: em(31.25);
		line-height: 1;
		color: $color-white;
		margin: -80px 0 0 0;

		@include media-breakpoint-down(lg) {
			font-size: em(20);
			margin-top: -7px;
		}

		span {
			background-color: $color-primary;
			padding: 12px;

			&:first-child {
				margin: 0 0 4px 0;
			}
		}
	}

	img {
		object-fit: cover;
		height: 220px;
		width: 100%;
	}

	&__item {
		@include media-breakpoint-up(lg) {
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			background-image: var(--slider-image);
			min-height: 520px;
		}
	}
}
