.b-partners {
	background-color: $color-fill-card;

	&__heading {
		font-weight: 700;
		font-size: em(20);
		line-height: normal;
		color: $color-secondary;
		padding: 24px 12px;
		margin: 0;
		background-color: $color-white;

		@include media-breakpoint-up(lg) {
			font-size: em(32);
			padding: 48px 24px;
		}
	}

	&__images {
		padding: 48px 0;
	}
}
