/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
	background-color: $color-primary;
	padding: 32px 0;

	&__col {
		gap: 48px;
	}

	&__info {
		img {
			margin-bottom: 24px;
			max-width: 160px;
		}
	}

	&__socials {
		margin-bottom: 24px;
		gap: 10px;

		a {
			transition: $global-transition;

			&:hover {
				filter: invert(1);
			}
		}
	}

	&__copy {
		@include media-breakpoint-down(lg) {
			text-align: center !important;
		}
	}

	&__info,
	&__copy {
		max-width: 202px;
		width: 100%;
		font-size: em(14);
		line-height: 1.71;
	}
}
