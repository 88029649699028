/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
	padding-top: 26px;
	padding-bottom: 26px;

	&__logo {
		margin-right: 61px;

		@include media-breakpoint-up(lg) {
			margin-right: 48px;
		}
	}

	&__number {
		font-weight: 500;
		font-size: em(14);
		line-height: 1.43;
		letter-spacing: 0.1px;
		margin-left: 12px;
		color: $color-dark-orange;
	}
}
